import {noop} from './utils.js';

export default function observe(PerformanceObserver, type, cb = noop, buffered = true) {
    if (!PerformanceObserver) {
        return;
    }

    const {supportedEntryTypes} = PerformanceObserver;
    if (!supportedEntryTypes || !supportedEntryTypes.includes(type)) {
        return;
    }

    const observer = new PerformanceObserver((list, observer) => cb(list.getEntries(), observer));
    try {
        observer.observe({
            type,
            buffered
        });
    } catch (e) {
        observer.observe({
            entryTypes: [type]
        });
    }
    return observer;
}
