import observe from '../utils/observe.js';
import config from '../utils/config.js';

export default function longtasks([{requestAnimationFrame, cancelAnimationFrame}, performance, PerformanceObserver], cb) {
    const observer = observe(PerformanceObserver, 'longtask', cb, false); // false because buffered not fully supported yet for longtask
    if (observer) {
        return () => observer.disconnect();
    }

    // Use polyfill
    let startTime = performance.now();
    const {longTask} = config;
    let id = requestAnimationFrame(getTaskDuration);
    return () => cancelAnimationFrame(id);

    function getTaskDuration(now) {
        const duration = now - startTime;
        if (duration >= longTask) {
            cb([{startTime, duration}]);
        }
        startTime = now;
        id = requestAnimationFrame(getTaskDuration);
    }
}
