import observe from '../utils/observe.js';
import {CLS_FACTOR} from '../utils/constants.js';
import {rejector, round, max} from '../utils/utils.js';

const entryType = 'layout-shift';

export default function cls([, , PerformanceObserver], interactive, lastPaint) {
    return Promise.all([interactive, lastPaint])
        .then(([{tti}, {startTime: lcp}]) => {
            const finish = max(tti, lcp);
            const observer = observe(PerformanceObserver, entryType);
            const entries = observer?.takeRecords?.();
            if (!entries) {
                throw entryType;
            }
            observer.disconnect();
            let cls = 0;
            let countCls = 0;
            for (const {lastInputTime, startTime, value} of entries) {
                if (lastInputTime > 0 || startTime > finish) {
                    break;
                }
                cls += value;
                ++countCls;
            }
            return {
                entryType,
                cls: round(cls * CLS_FACTOR),
                countCls
            };
        })
        .catch(rejector(entryType));
}
