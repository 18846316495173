import {addField} from '../utils/utils.js';

const entryType = 'wix-start';

export default function wixStart([window]) {
    const wixBiSession = getWixBiSession(window);
    if (!wixBiSession) {
        return Promise.reject(entryType);
    }

    const result = {
        entryType
    };

    addField(result, 'msid', window.rendererModel?.metaSiteId || wixBiSession.msId);

    const {thunderboltVersion, viewerModel} = window;
    const commonConfig = window.commonConfig || viewerModel?.siteFeaturesConfigs?.commonConfig;
    addField(result, 'brand', commonConfig?.brand);

    addField(result, 'viewerName', thunderboltVersion ? 'thunderbolt' : wixBiSession.renderType);
    addField(result, 'v', thunderboltVersion || window.boltVersion);

    addField(result, 'dc', wixBiSession.dc || viewerModel?.site.dc);

    return Promise.resolve(result);
}

export function getWixBiSession(window) {
    return window.wixBiSession || window.bi?.wixBiSession;
}
