import hidden from './entries/hidden.js';
import client from './entries/client.js';
import navStart from './entries/navStart.js';
import navFinish from './entries/navFinish.js';
import wixStart from './entries/wixStart.js';
import wixFinish from './entries/wixFinish.js';
import scripts from './entries/scripts.js';
import images from './entries/images.js';
import fonts from './entries/fonts.js';
import fcp from './entries/fcp.js';
import tti_tbt from './entries/tti-tbt.js';
import lcp from './entries/lcp.js';
import cls from './entries/cls.js';
import fid from './entries/fid.js';

import config from './utils/config.js';
import {batch} from './utils/utils.js';

import loadInfo from './actions/loadInfo.js';
import markAndMeasure from './actions/markAndMeasure.js';
import log from './actions/log.js';

const TAG_ID = 'wix-perf-measure';

measure(window);

function measure(window) {
    if (typeof Promise !== 'function' || !Promise.allSettled) {
        return;
    }

    const {document, performance} = window;
    const script = document.currentScript || document.getElementById(TAG_ID);
    if (script) {
        config.load(script.dataset);
    }

    const measurements = obtainMeasurements([window, performance, window.PerformanceObserver, window.setTimeout, window.clearTimeout]);
    loadInfo(window, document, script, measurements);
    Object.freeze(measurements);

    const {noMeasure, log: isLogging, label} = config;
    const together = measurements.slice(0, -2);
    const individually = measurements.slice(-2);
    if (!noMeasure) {
        markAndMeasure(performance, together);
    }
    if (isLogging) {
        log(together, individually);
    }

    window[label] = measurements;
    const event = new window.CustomEvent(label, {
        detail: measurements
    });
    window.dispatchEvent(event);
}

function obtainMeasurements(state) {
    const environment = batch('environment', client(state), navStart(state), wixStart(state));

    const initialPaint = fcp(state);
    const interaction = fid(state);
    const interactive = tti_tbt(state, initialPaint, interaction);
    const lastPaint = lcp(state, initialPaint, interactive);
    const layoutShift = cls(state, interactive, lastPaint);

    const ss = scripts(state);
    const is = images(state);
    const fs = fonts(state);

    const loaded = batch('loaded', interactive, lastPaint, layoutShift, ss, is, fs, navFinish(state), wixFinish(state, interactive));

    return [environment, initialPaint, loaded, interaction, hidden(state)];
}
