import config from '../utils/config.js';
import observeResources, {isScript, isAjax} from '../utils/observeResources.js';
import {noop, round, max, min, rejector} from '../utils/utils.js';
import {dcl} from '../utils/windowEvents.js';
import longtasks from '../polyfills/longtasks.js';

const entryType = 'interactive';

export default function tti_tbt(state, paints, interaction) {
    const [window, , PerformanceObserver, setTimeout, clearTimeout] = state;
    const {resourceDebounce, taskDelta} = config;

    return paints
        .then(({fcp}) => new Promise(resolve => {
            let finished = fcp;
            let stopResources = false;

            let taskTimer = 0;
            const lts = [];
            const stopLT = longtasks(state, entries => {
                lts.push(...entries);
                if (taskTimer) {
                    clearTimeout(taskTimer);
                    scheduleDone();
                }
            });

            const dclPromise = dcl(window);
            let resourceTimer = setTimeout(scheduleDone, resourceDebounce);
            observeResources(PerformanceObserver, (rs, finish) => {
                if (stopResources) {
                    clearTimeout(resourceTimer);
                    finish();
                    return;
                }
                rs = rs.filter(r => isAjax(r) || isScript(r));
                if (rs.length) {
                    finished = rs.reduce((acc, {startTime, duration}) => max(acc, startTime + duration), finished);
                    dclPromise.then(dclTime => {
                        finished = max(dclTime, finished);
                        clearTimeout(resourceTimer);
                        resourceTimer = setTimeout(() => {
                            finish();
                            scheduleDone();
                        }, resourceDebounce);
                    });
                }
            });

            interaction.then(({startTime, delay}) => doneTasks(startTime + delay), noop);

            function doneTasks(interactionEnd = 1000000) {
                stopLT();
                resolve([
                    lts,
                    fcp,
                    finished,
                    interactionEnd
                ]);
            }
            function scheduleDone() {
                stopResources = true;
                taskTimer = setTimeout(doneTasks, taskDelta);
            }
        }))
        .then(([lts, fcp, finished, interactionEnd]) => {
            const tti = calc_tti(lts, fcp, finished, interactionEnd, taskDelta);
            const {document, innerHeight} = window;
            const tbt = calc_tbt(lts, tti);
            const result = {
                entryType,
                tti: round(tti),
                tbt: round(tbt),
                iframes: document.querySelectorAll('iframe').length
            };
            const pageHeight = max(document.body.offsetHeight, innerHeight);
            if (pageHeight > 0) {
                result.screens = round(document.body.scrollHeight / pageHeight);
            }
            return result;
        })
        .catch(rejector(entryType));
}

function calc_tti(lts, fcp, finished, interactionEnd, taskDelta) {
    let tail = lts.findIndex(({startTime, duration}) => {
        if (startTime > finished + taskDelta) {
            return true;
        }
        finished = max(finished, startTime + duration);
    });
    if (tail === -1) {
        tail = lts.length;
    }
    const found = tail > 0 ? (llt => llt.startTime + llt.duration)(lts[tail - 1]) : 0;
    return max(min(found, interactionEnd), fcp);
}

function calc_tbt(lts, tti) {
    let tbt = 0;
    for (let i = 0; i < lts.length; ++i) {
        const {startTime, duration} = lts[i];
        if (startTime > tti) {
            break;
        }
        tbt += duration - 50;
    }
    return tbt;
}
