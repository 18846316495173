import {addField} from '../utils/utils.js';
import {getWixBiSession} from './wixStart.js';

const entryType = 'wix-finish';

const FIELDS = [
    'cdn',
    'microPop',
    'caching',
    'is_rollout',
    'is_platform_loaded',
    'maybeBot'
];
const MAP_NAME = {
    caching: 'ssrCaching',
    is_rollout: 'isRollout',
    is_platform_loaded: 'isPlatformLoaded'
};

export default function wixFinish([window], interactive) {
    return interactive.then(() => {
        const wixBiSession = getWixBiSession(window);
        if (!wixBiSession) {
            throw entryType;
        }

        const isSsr = !window.clientSideRender;
        const result = {
            entryType,
            isSsr
        };

        addField(result, 'pageId', window.rendererModel?.landingPageId || window.firstPageId);
        if (isSsr) {
            addField(result, 'ssrDuration', window.ssrInfo?.timeSpentInSSR);
        }

        FIELDS.forEach(field => addField(result, MAP_NAME[field] || field, wixBiSession[field]));

        return result;
    });
}
