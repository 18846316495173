import observe from './observe.js';

export default function promisifyObserver(PerformanceObserver, entryType, cb, buffered) {
    return new Promise((resolve, reject) => {
        const observer = observe(PerformanceObserver, entryType, entries => cb(entries, result => {
            observer.disconnect();
            resolve(result);
        }), buffered);
        if (!observer) {
            reject(entryType);
        }
    });
}
