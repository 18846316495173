import {addEventListener} from './utils.js';

export const dcl = once(readyState => readyState !== 'loading', 'DOMContentLoaded');
export const load = once(readyState => readyState === 'complete', 'load');

function stateToPromise(window, isState, eventName) {
    return new Promise(resolve => {
        if (isState(window.document.readyState)) {
            resolve(0);
        } else {
            addEventListener(window, eventName, ({timeStamp}) => resolve(timeStamp));
        }
    });
}
function once(isState, eventName) {
    let promise;
    return function (window) {
        if (!promise) {
            promise = stateToPromise(window, isState, eventName);
        }
        return promise;
    };
}