import {round, addEventListener} from '../utils/utils.js';

export default function hidden([window]) {
    const VISIBILITY  = 'visibilitychange';
    const UNLOAD = 'unload';

    return new Promise(resolve => {
        if (document.hidden) {
            resolve(makeResult(VISIBILITY));
            return;
        }

        addEventListener(window.document, VISIBILITY, ({timeStamp}) => resolve(makeResult(VISIBILITY, timeStamp)));
        addEventListener(window, UNLOAD, ({timeStamp}) => resolve(makeResult(UNLOAD, timeStamp)));

        function makeResult(type, timeStamp = 0) {
            return {
                entryType: 'visibility',
                type,
                startTime: round(timeStamp)
            };
        }
    });
}