import config from './config.js';
import observe from './observe.js';

export const isScript = ({initiatorType, name}) => initiatorType === 'script' || (initiatorType === 'link' && /\.js$/.test(name));
export const isAjax = ({initiatorType}) => initiatorType === 'fetch' || initiatorType === 'xmlhttprequest';
export const isImage = ({initiatorType, name}) => initiatorType === 'img' || initiatorType === 'css' && /\.(?:jpe?g|png|webp|gif)/i.test(name);
export const isFont = ({initiatorType, name}) => (initiatorType === 'css' && name.includes('font')) || /\.(?:woff2?|ttf|eot)/i.test(name);

let observer;
let cbs = [];

export default function observeResources(PerformanceObserver, cb) {
    if (cbs.push(cb) === 1) {
        const ignores = config.ignoreResources.split(',');
        observer = observe(PerformanceObserver, 'resource', entries => {
            entries = entries.filter(({name}) => !ignores.some(ignore => name.includes(ignore)));
            if (entries.length) {
                cbs.forEach(cb => {
                    cb(entries, () => {
                        const index = cbs.indexOf(cb);
                        cbs.splice(index, 1);
                        if (cbs.length === 0) {
                            observer.disconnect();
                        }
                    });
                });
            }
        });
    }
    return observer;
}
